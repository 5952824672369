function init() {}

function log(error) {
  console.log(error);
}

const logService = {
    init,
    log
}

export default logService;
